/* eslint-disable sort-keys-fix/sort-keys-fix */
import React from "react";
import { Grid, GridDirection, Stack } from "@mui/material";
import { SectionContainer } from "../containers";
import { Media, MediaPlacementType, PpMedia } from "../media";
import { Container, IntroSectionName, Paragraph } from "./styles";

export type PpIntroduction = {
  media?: PpMedia["media"];
  mediaPlacement?: string;
  paragraph: string;
  shadowHeader: string;
};

export function Introduction({
  media,
  mediaPlacement,
  paragraph,
  shadowHeader,
  ...rest
}: PpIntroduction): React.ReactElement {
  const direction = {
    xs: "column" as GridDirection,
    md: "column" as GridDirection,
  };

  switch (mediaPlacement) {
    case MediaPlacementType.TOP:
      direction.xs = "column-reverse";
      direction.md = "column-reverse";
      break;
    case MediaPlacementType.RIGHT:
      direction.md = "row";
      break;
    case MediaPlacementType.LEFT:
      direction.md = "row-reverse";
      break;
    default:
      break;
  }

  const verticalLayout =
    mediaPlacement === MediaPlacementType.TOP ||
    mediaPlacement === MediaPlacementType.BOTTOM;

  return (
    <SectionContainer {...rest}>
      <Container $verticalLayout={verticalLayout}>
        <Grid
          container
          alignItems="center"
          direction={direction}
          justifyContent="center"
          spacing={{ xs: 4, md: verticalLayout ? 4 : 8 }}
        >
          <Grid item md={verticalLayout ? false : 6}>
            <Stack alignItems="center">
              <IntroSectionName
                gutterBottom
                align="center"
                color="primary.light"
                component="h2"
                variant="section--name_intro"
              >
                {shadowHeader}
              </IntroSectionName>
              <Paragraph
                align="center"
                color="primary"
                text={paragraph}
                variant="intro"
              />
            </Stack>
          </Grid>
          <Grid
            item
            zeroMinWidth
            md={verticalLayout ? false : 6}
            style={{ maxWidth: "100%" }}
          >
            <Media media={media} />
          </Grid>
        </Grid>
      </Container>
    </SectionContainer>
  );
}
