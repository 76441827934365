import React, { useEffect, useState } from "react";
import { ToggleButtonGroup, Typography } from "@mui/material";
import { StyledContainer, StyledToggleButton } from "./styles";

export type PpToggleBtnGroup = {
  onChange: (arg: string) => void;
  value: string;
  values: Array<{
    label?: string;
    name: string;
  }>;
};

export function ToggleBtnGroup({
  onChange,
  value,
  values,
}: PpToggleBtnGroup): React.ReactElement {
  const [currentValue, setCurrentValue] = useState<string>(value);

  function handleChange(
    _: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ): void {
    if (newValue) {
      setCurrentValue(newValue);
      onChange(newValue);
    }
  }

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <ToggleButtonGroup
      exclusive
      aria-label="toggle-button-group"
      value={currentValue}
      onChange={handleChange}
    >
      {values.map((v, index) => (
        <StyledToggleButton
          key={v.name}
          $index={index - values.length + 1}
          aria-label={v.name}
          value={v.name}
        >
          <Typography
            sx={{ display: { lg: "none", xs: "block" } }}
            variant="toggle-btn--title"
          >
            {v.name}
          </Typography>
          <StyledContainer sx={{ display: { lg: "flex", xs: "none" } }}>
            {v.label ? (
              <Typography variant="toggle-btn--label">{v.label}</Typography>
            ) : null}
            <Typography variant="toggle-btn--title">{v.name}</Typography>
          </StyledContainer>
        </StyledToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
